import { useEffect, useState, useRef } from 'react'
import { isMortaUploadedUrl } from 'helpers/utils'
import api from 'helpers/api'

export default function useSignedUrl(url: string | undefined) {
  const [signedUrl, setSignedUrl] = useState<string | undefined>(url)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const controller = useRef(new AbortController())

  useEffect(() => {
    // Reset state when URL changes
    setSignedUrl(url)
    setError(null)

    // Only sign URLs that match the Morta upload pattern
    if (url && isMortaUploadedUrl(url)) {
      setLoading(true)

      // Create a new controller for each request
      controller.current = new AbortController()
      const signal = controller.current.signal

      api
        .signUrl(url, signal)
        .then((response) => {
          // Only update state if component is still mounted and request wasn't aborted
          if (!signal.aborted) {
            setSignedUrl(response.data.url)
            setLoading(false)
          }
        })
        .catch((err) => {
          // Only update state if component is still mounted and request wasn't aborted
          if (!signal.aborted) {
            setError(err)
            setLoading(false)
          }
        })
    } else {
      setLoading(false)
    }
    // Cleanup: abort any pending requests when component unmounts or URL changes
    return () => {
      controller.current.abort()
    }
  }, [url])
  return { signedUrl, loading, error }
}
