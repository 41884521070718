import React, { useEffect, useState, ImgHTMLAttributes, ReactElement } from 'react'
import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'
import { ContentBlock, ContentState } from 'draft-js'
import useApplicationStore from 'hooks/application'

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  block: ContentBlock
  contentState: ContentState
}

export default React.forwardRef<HTMLImageElement, ImageProps>(function EmbeddedImage(props, ref): ReactElement {
  const { displayErrorMessage } = useApplicationStore()
  const { block, contentState, style, ...elementProps } = props

  const [src, setSrc] = useState<string | undefined>(
    contentState.getEntity(block.getEntityAt(0)).getData().src?.url ||
      contentState.getEntity(block.getEntityAt(0)).getData().src
  )
  const [ready, setReady] = React.useState(false)

  const signUrl = async () => {
    if (src) {
      try {
        const signedFileTokenResponse = await api.signUrl(src)
        setSrc(signedFileTokenResponse.data.url)
      } catch (e) {
        displayErrorMessage(e)
        setSrc(undefined)
      }
      setReady(true)
    }
  }

  useEffect(() => {
    if (src && isMortaUploadedUrl(src)) {
      signUrl()
    } else {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return <span>Loading image...</span>
  }

  if (!src) {
    return <span>Image not available</span>
  }

  return <img {...elementProps} ref={ref} src={src} role="presentation" style={style} {...elementProps} />
})
