import React, { useEffect } from 'react'
import { IFile } from 'types'
import { IResponseFilePreviousVersion } from 'types'
import useApplicationStore from 'hooks/application'
import useSignedUrl from 'hooks/url'

interface Props {
  file: IResponseFilePreviousVersion | IFile
}

const SignedFileDownloadLink: React.FC<Props> = ({ file }: Props) => {
  const { displayErrorMessage } = useApplicationStore()
  const { signedUrl, error } = useSignedUrl(file.url)

  useEffect(() => {
    if (error) {
      displayErrorMessage(error)
    }
  }, [error])

  if (!signedUrl) {
    return <span>{file.filename}</span>
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={signedUrl} download>
      {file.filename}
    </a>
  )
}

export default SignedFileDownloadLink
