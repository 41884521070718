import React, { useState, useEffect } from 'react'
import { IFile } from 'types'
import api from 'helpers/api'
import { getAuthToken } from 'helpers/auth'
import Button from 'components/button'
import DeleteResource from 'components/delete'
import useApplicationStore from 'hooks/application'

interface PreviewProps {
  file: IFile
  fileIndex: number
  setFileIndex: (newFileIndex: number) => void
  firstFile: boolean
  lastFile: boolean
  visible: boolean
  tableId: string
  isAdmin: boolean
  isContributor: boolean
  isReadOnly: boolean
  handleRemoveFile: (index: number) => void
  handleAddFile: () => void
  previewRef: React.RefObject<HTMLDivElement>
  columnId?: string
}

const Preview: React.FC<PreviewProps> = ({
  file,
  fileIndex,
  setFileIndex,
  firstFile,
  lastFile,
  visible,
  tableId,
  isAdmin,
  isContributor,
  isReadOnly,
  handleRemoveFile,
  handleAddFile,
  previewRef,
  columnId
}) => {
  const { displayErrorMessage } = useApplicationStore()

  const [ready, setReady] = useState(false)
  const [done, setDone] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [src, setSrc] = useState<string>('about:blank')
  const [deleteResourceModal, setDeleteResourceModal] = useState<boolean>(false)

  const renderIframe = async () => {
    const token = getAuthToken()
    if (ready || !token) return

    let fullUrl = ''
    if (file.version === 1) {
      const url =
        `/table/${tableId}/file` +
        encodeURIComponent(
          JSON.stringify({
            columnId,
            filename: file.filename
          })
        )
      const apiUrl = process.env.REACT_APP_API_HOST
      const apiVersion = process.env.REACT_APP_API_VERSION
      fullUrl = `${apiUrl}${apiVersion}${url}`
    } else {
      try {
        const fileCall = await api.signUrl(file.url)
        fullUrl = fileCall.data.url
      } catch (err) {
        displayErrorMessage(err)
        setReady(false)
        return
      }
    }

    const xhr = new XMLHttpRequest()
    xhr.open('GET', fullUrl)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('Authorization', token)
    xhr.send()

    xhr.onload = function () {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          const file = window.URL.createObjectURL(this.response)
          setSrc(file)
          setReady(true)
        } else {
          setReady(false)
          this.response.text().then((text: any) => {
            try {
              const response = JSON.parse(text)
              if (response.detail) {
                setErrorMessage(response.detail.message)
              } else {
                setErrorMessage(text)
              }
            } catch (e) {
              setErrorMessage(text)
            }
          })
        }
        setDone(true)
      }
    }
  }

  const setFullScreen = () => {
    const element = previewRef.current

    if (!element) {
      displayErrorMessage('No element to fullscreen')
      return
    }

    if (document.fullscreenElement) {
      document.exitFullscreen().catch((error) => {
        displayErrorMessage(error)
      })
    } else {
      element.requestFullscreen().catch((error) => {
        displayErrorMessage(error)
      })
    }
  }
  useEffect(() => {
    renderIframe()
  }, [])

  const canAddFiles = (isAdmin || isContributor) && !isReadOnly
  const canDeleteFiles = (isAdmin || isContributor) && !isReadOnly
  const canDownloadFiles = ready && (isAdmin || isContributor || isReadOnly)

  return (
    <div className={`${visible ? 'flex' : 'hidden'} w-full h-full flex-column items-center justify-center `}>
      <div className="flex items-center justify-center w-full" style={{ marginBottom: '20px' }}>
        <Button disabled={firstFile} onClick={() => setFileIndex(fileIndex - 1)} style={{ marginRight: 'auto' }}>
          Previous
        </Button>
        <Button onClick={() => setFullScreen()}>Toggle Fullscreen</Button>
        {canDownloadFiles && (
          <a
            href={src}
            download={file.filename}
            style={{ pointerEvents: !ready ? 'none' : 'all', cursor: !ready ? 'not-allowed' : 'default' }}
          >
            <Button style={{ marginLeft: '10px' }} isLoading={!done}>
              Download
            </Button>
          </a>
        )}
        {canAddFiles && (
          <Button internalType="accept" style={{ marginLeft: '10px' }} onClick={() => handleAddFile()}>
            Add
          </Button>
        )}
        {canDeleteFiles && (
          <Button internalType="danger" style={{ marginLeft: '10px' }} onClick={() => setDeleteResourceModal(true)}>
            Delete
          </Button>
        )}
        <Button disabled={lastFile} className="ml-auto" onClick={() => setFileIndex(fileIndex + 1)}>
          Next
        </Button>
      </div>

      <div className="flex items-center justify-center w-full" style={{ height: `calc(100% - 60px)` }}>
        {done &&
          (ready ? (
            <object
              id={file.filename}
              className="w-full h-full"
              data={src}
              name={file.filename}
              type={file.mimetype}
              style={{ objectFit: 'scale-down' }}
            >
              This browser does not support previewing this file type. Please download the file to view it.
            </object>
          ) : (
            <div className="flex items-center justify-center">{errorMessage}</div>
          ))}

        {!done && (
          <div className="flex items-center justify-center">
            <div className="spin w-full h-full" />
          </div>
        )}
      </div>

      {deleteResourceModal && (
        <DeleteResource
          id="delete-file-modal"
          open={deleteResourceModal}
          setOpen={setDeleteResourceModal}
          resourceName={file.filename}
          deleteResource={() => handleRemoveFile(fileIndex)}
        />
      )}
    </div>
  )
}

export default Preview
