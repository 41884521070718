import React, { forwardRef, useState, useEffect, ReactElement, VideoHTMLAttributes } from 'react'
import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'
import { getAuthToken } from 'helpers/auth'
import useApplicationStore from 'hooks/application'

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  blockProps: { src: string }
}

export default forwardRef<HTMLVideoElement, VideoProps>(function EmbeddedVideo(props, ref): ReactElement {
  const { displayErrorMessage } = useApplicationStore()

  const { blockProps, style, ...elementProps } = props

  const [mortaSrc, setMortaSrc] = useState<string | undefined>()
  const [ready, setReady] = useState(false)

  const signUrl = async () => {
    const token = getAuthToken()
    if (ready || !token) return

    if (!blockProps.src) {
      setReady(true)
      return
    }

    try {
      const signedFileTokenResponse = await api.signUrl(blockProps.src)
      const xhr = new XMLHttpRequest()
      xhr.open('GET', signedFileTokenResponse.data.url)
      xhr.responseType = 'blob'
      xhr.setRequestHeader('Authorization', token)
      xhr.send()

      xhr.onload = function () {
        if (this.readyState === this.DONE) {
          if (this.status === 200) {
            const file = window.URL.createObjectURL(this.response)
            setMortaSrc(file)
            setReady(true)
          } else {
            console.error('Something went wrong')
            setReady(true)
          }
        }
      }
    } catch (err) {
      displayErrorMessage(err)
      setReady(true)
      setMortaSrc(undefined)
      return
    }
  }

  useEffect(() => {
    if (blockProps.src && isMortaUploadedUrl(blockProps.src)) {
      signUrl()
    } else {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return <span>Loading video...</span>
  }

  if (!mortaSrc) {
    return <span>Video not available</span>
  }

  return <video ref={ref} {...elementProps} controls src={mortaSrc} style={style} {...elementProps} />
})
