import React, { useState, useRef, useEffect } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { HamburgerMenu } from 'components/icons'
import { ITableViewColumn } from 'types'

const ColumnOrderMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const visibleColumns = spreadsheetData.viewDetails.columns.filter(
    (column) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)
  )

  const columnRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const isMounted = useRef(true)
  const dragImageRef = useRef<HTMLDivElement | null>(null)

  const [selectedColumns, setSelectedColumns] = useState<string[]>([])
  const [columnsOrder, setColumnsOrder] = useState<ITableViewColumn[]>(visibleColumns)

  const [originalColumnsOrder, setOriginalColumnsOrder] = useState<ITableViewColumn[]>([])
  const [dropOccurred, setDropOccurred] = useState(false)

  useEffect(() => {
    setColumnsOrder(visibleColumns)
  }, [spreadsheetData.viewDetails.columns])

  useEffect(() => {
    return () => {
      isMounted.current = false
      if (dragImageRef.current && document.body.contains(dragImageRef.current)) {
        try {
          document.body.removeChild(dragImageRef.current)
        } catch (e) {}
      }
    }
  }, [])

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', selectedColumns.join(','))

    setOriginalColumnsOrder(columnsOrder)

    const dragImage = document.createElement('div')
    dragImage.style.display = 'inline-flex'
    dragImage.style.flexDirection = 'column'
    dragImage.style.position = 'absolute'
    dragImage.style.top = '-1000px' // Position it off-screen
    dragImage.style.left = '-1000px'

    selectedColumns.forEach((colId) => {
      const colNode = columnRefs.current[colId]
      if (colNode) {
        const clone = colNode.cloneNode(true) as HTMLElement
        clone.style.width = `${colNode.offsetWidth}px`
        clone.style.height = `${colNode.offsetHeight}px`
        dragImage.appendChild(clone)
      }
    })

    document.body.appendChild(dragImage)
    dragImageRef.current = dragImage
    event.dataTransfer.setDragImage(dragImage, 0, 0)

    requestAnimationFrame(() => {
      if (isMounted.current && dragImageRef.current && document.body.contains(dragImageRef.current)) {
        try {
          document.body.removeChild(dragImageRef.current)
          dragImageRef.current = null
        } catch (e) {}
      }
    })
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>, overColumnPublicId?: string) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'

    if (!overColumnPublicId) {
      setColumnsOrder(originalColumnsOrder)
      return
    }

    const overColumnElement = columnRefs.current[overColumnPublicId]
    if (overColumnElement) {
      const rect = overColumnElement.getBoundingClientRect()
      const offsetY = event.clientY - rect.top
      const height = rect.height
      const isTopHalf = offsetY < height / 2

      const overColumnIndex = columnsOrder.findIndex((col) => col.publicId === overColumnPublicId)
      const draggingColumns = selectedColumns
      const draggingColumnsIndices = draggingColumns.map((colId) =>
        columnsOrder.findIndex((col) => col.publicId === colId)
      )

      const newOrder = columnsOrder.filter((col) => !draggingColumns.includes(col.publicId))

      let insertIndex = overColumnIndex
      if (!isTopHalf) {
        insertIndex += 1
      }

      if (insertIndex > Math.min(...draggingColumnsIndices)) {
        insertIndex -= draggingColumns.length
      }

      newOrder.splice(insertIndex, 0, ...columnsOrder.filter((col) => draggingColumns.includes(col.publicId)))

      newOrder.forEach((col, index) => {
        col.sortOrder = index
      })

      setColumnsOrder(newOrder)
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDropOccurred(true)
    saveNewColumnOrder(columnsOrder)
    setSelectedColumns([])
  }

  const handleDragEnd = () => {
    if (!dropOccurred) {
      setColumnsOrder(originalColumnsOrder)
    }
    setDropOccurred(false)

    if (dragImageRef.current && document.body.contains(dragImageRef.current)) {
      try {
        document.body.removeChild(dragImageRef.current)
        dragImageRef.current = null
      } catch (e) {}
    }
  }

  const saveNewColumnOrder = async (newColumns: ITableViewColumn[]) => {
    setSpreadsheetData({
      type: 'CHANGE_ALL_COLUMNS_ORDER',
      columns: newColumns
    })
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px' }}>
        <li
          className="flex items-center font-bold bg-light-grey"
          style={{
            padding: '10px 20px'
          }}
        >
          <span>Column Order</span>
          <span className="flex items-center text-xs text-secondary ml-auto">
            <pre
              className="border-1 border-solid border-black rounded bg-grey"
              style={{ padding: '3px', marginRight: '3px' }}
            >
              Shft
            </pre>{' '}
            to select multiple
          </span>
        </li>

        <div className="overflow-y-auto" style={{ padding: '10px', maxHeight: '400px' }}>
          {columnsOrder.map((column) => {
            return (
              <div
                key={column.publicId}
                ref={(el) => {
                  if (el) {
                    columnRefs.current[column.publicId] = el
                  }
                }}
                className={`flex items-center justify-between p-2 ${
                  selectedColumns.includes(column.publicId) ? 'cursor-grab' : 'cursor-pointer'
                } ${selectedColumns.includes(column.publicId) ? 'bg-light-blue' : ''}`}
                style={{ padding: '10px' }}
                draggable={selectedColumns.includes(column.publicId)}
                onClick={(event) => {
                  if (event.shiftKey) {
                    const newSelectedColumns = [...selectedColumns]
                    const columnIndex = newSelectedColumns.indexOf(column.publicId)
                    if (columnIndex === -1) {
                      newSelectedColumns.push(column.publicId)
                    } else {
                      newSelectedColumns.splice(columnIndex, 1)
                    }
                    setSelectedColumns(newSelectedColumns)
                    return
                  } else {
                    const columnIndex = selectedColumns.indexOf(column.publicId)
                    if (columnIndex === -1) {
                      setSelectedColumns([column.publicId])
                    } else {
                      setSelectedColumns([])
                    }
                  }
                }}
                onDragStart={(event) => handleDragStart(event)}
                onDragOver={(event) => handleDragOver(event, column.publicId)}
                onDragEnd={handleDragEnd}
                onDrop={(event) => {
                  event.stopPropagation()
                  handleDrop(event)
                }}
                onDragEnter={(event) => event.preventDefault()}
              >
                <span className="mr-auto">{column.name}</span>
                {selectedColumns.includes(column.publicId) && <HamburgerMenu />}
              </div>
            )
          })}
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(ColumnOrderMenu)
