import React, { useState, useEffect, ReactElement, AnchorHTMLAttributes } from 'react'
import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'
import useApplicationStore from 'hooks/application'

export interface FileProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  blockProps: { src: string; filename: string }
}

export default React.forwardRef<HTMLAnchorElement, FileProps>(function EmbeddedFile(props, ref): ReactElement {
  const { displayErrorMessage } = useApplicationStore()
  const { blockProps } = props
  const [src, setSrc] = useState<string | undefined>(blockProps.src)
  const [ready, setReady] = useState(false)

  const signUrl = async () => {
    if (src) {
      try {
        const signedFileTokenResponse = await api.signUrl(src)
        setSrc(signedFileTokenResponse.data.url)
      } catch (err) {
        displayErrorMessage(err)
        setSrc(undefined)
      }
      setReady(true)
    }
  }

  useEffect(() => {
    if (src && isMortaUploadedUrl(src)) {
      signUrl()
    } else {
      setReady(true)
    }
  }, [])

  if (!src || !ready) {
    return <span>{blockProps.filename}</span>
  }

  return (
    <a
      href={ready ? src : '#still-loading'}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
      download={blockProps.filename}
    >
      {blockProps.filename}
    </a>
  )
})
