import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import * as serviceWorker from './serviceWorker'
import { history } from 'helpers/history'
import MortaSnackbar from 'components/snackbar'
import * as Sentry from '@sentry/react'
import Routes from 'routes'
import { AuthProvider } from 'hooks/auth'
import { ApplicationProvider } from 'hooks/application'
import { ProjectProvider } from 'hooks/project'
import { SocketProvider } from 'hooks/socket'
import 'style/style.scss'

const version = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_GIT_SHORT_HASH : 'development'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e9aad957c18041278f2fe9a7e0b7b2a4@o381276.ingest.sentry.io/5208367',
    ignoreErrors: [
      'APIError:',
      'AbortError',
      'NotAllowedError',
      'Failed to fetch',
      'ResizeObserver',
      'Failed to fetch',
      'A valid "Authorization" token was not found in the request headers',
      'network error',
      'Load failed',
      'The operation is insecure.',
      'NotAllowedError: Document is not focused',
      'Unable to send Replay - max retries exceeded',
      'NetworkError when attempting to fetch resource',
      'Flowable <',
      'The requested URL was not found on the server. If you entered the URL manually please check your spelling and try again',
      'You do not have the appropriate permissions to do that.',
      "Can't find variable: M",
      'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:',
      'antifingerprint not defined yet. will try and handle event after its ready'
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: `web-app@${version}`,
    tracesSampleRate: 0.05,
    beforeSend(event) {
      // 1) First ignore ResizeObserver
      if (
        event &&
        event.message &&
        event.message.match &&
        typeof event.message.match === 'function' &&
        event.message.match(/ResizeObserver loop/i)
      ) {
        return null
      }

      // 2) Then check if any frame in the exception stacktrace starts with "chrome-extension"
      const exceptionValues = event?.exception?.values ?? []
      for (const exception of exceptionValues) {
        const frames = exception?.stacktrace?.frames ?? []
        for (const frame of frames) {
          if (frame?.filename?.includes('chrome-extension://')) {
            // Ignore this event entirely if it looks like a chrome-extension error
            return null
          }
        }
      }

      // Otherwise, report the event as usual
      return event
    }
  })
}

const App = () => {
  return (
    <Router history={history}>
      <AuthProvider>
        <ApplicationProvider>
          <ProjectProvider>
            <SocketProvider>
              <MortaSnackbar />
              <Routes />
            </SocketProvider>
          </ProjectProvider>
        </ApplicationProvider>
      </AuthProvider>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
