import React, { useState, useEffect } from 'react'
import Modal from 'components/modal'
import QRCode from 'react-qr-code'
import Button from 'components/button'
import { useAuth } from 'hooks/auth'
import api from 'helpers/api'
import { I2FaSetup } from 'types'
import TwoFactorSetupForm from 'views/security/components/TwoFactorSetupForm'
import TwoFactorDisableForm from 'views/security/components/TwoFactorDisableForm'
import useApplicationStore from 'hooks/application'
import UserAccountForm from 'views/security/components/UserAccountForm'
import SupportAccessForm from 'views/security/components/SupportAccessForm'
import { generateRecoveryCodeTextFileContents } from 'views/security/utils'
import { downloadTextToClient } from 'helpers/fileDownload'
import { Copy } from 'components/icons'
import Checkbox from 'components/checkbox'

const Security: React.FC = () => {
  document.title = 'Account Security'
  const [show2FaSetup, setShow2FaSetup] = useState(false)
  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false)
  const [showDisable2Fa, setShowDisable2Fa] = useState(false)
  const [showSupportAccess, setShowSupportAccess] = useState({ open: false })
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([])
  const [setupData, setSetupData] = useState<I2FaSetup>()
  const [showChangeLogin, setShowChangeLogin] = useState(false)

  const { user, updateUser } = useAuth()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const handleSetup2Fa = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()

    const response = await api.setup2FA()
    setSetupData(response.data)
    setShow2FaSetup(true)
  }

  const handleComplete2FaSetup = async (code: string) => {
    try {
      const response = await api.complete2FaSetup(setupData!.secret, code)
      setShow2FaSetup(false)
      setRecoveryCodes(response.data)
      setShowRecoveryCodes(true)
      updateUser({ ...user!, is2FaEnabled: true })
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleTwoFactorDisable = async (code: string) => {
    try {
      await api.disable2FaSetup(code)
      setShowDisable2Fa(false)
      updateUser({ ...user!, is2FaEnabled: false })
      setSnackbarMessage({ status: 'success', message: 'Two factor authentication has been disabled for this account' })
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleDownloadRecoveryCodes = () => {
    const contents = generateRecoveryCodeTextFileContents(user!, recoveryCodes)
    downloadTextToClient('recovery-codes.txt', contents)
  }

  const handleAllowSupportAccess = (allowSupportAccess: boolean, twoFactorCode?: string) => {
    api
      .updateUserAccount(undefined, undefined, undefined, twoFactorCode, allowSupportAccess)
      .then(() => {
        updateUser({ ...user!, allowSupportAccess })
        setSnackbarMessage({
          status: allowSupportAccess ? 'success' : 'error',
          message: allowSupportAccess
            ? 'Morta support has been granted access to your account'
            : 'Morta support can no longer access to your account'
        })
        setShowSupportAccess({ open: false })
      })
      .catch((e) => {
        displayErrorMessage(e)
        setShowSupportAccess({ open: false })
      })
  }

  const handleEmailNotifications = (
    optOutWelcomeEmail?: boolean,
    optOutSyncEmail?: boolean,
    optOutAiEmail?: boolean,
    optOutHubEmail?: boolean,
    optOutDuplicationEmail?: boolean
  ) => {
    api
      .updateUserAccount(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        optOutWelcomeEmail,
        optOutSyncEmail,
        optOutAiEmail,
        optOutHubEmail,
        optOutDuplicationEmail
      )
      .then(() => {
        updateUser({
          ...user!,
          optOutWelcomeEmail,
          optOutSyncEmail,
          optOutAiEmail,
          optOutHubEmail,
          optOutDuplicationEmail
        })
        setSnackbarMessage({
          status: 'success',
          message: 'Your email notification preferences have been updated'
        })
      })
      .catch((e) => {
        displayErrorMessage(e)
      })
  }

  const [copying, setCopying] = React.useState(false)

  useEffect(() => {
    if (copying) {
      setTimeout(() => setCopying(false), 1000)
    }
  }, [copying])

  const value = recoveryCodes!.join('\n')

  const handleCopy = () => {
    setCopying(true)
    navigator.clipboard.writeText(value)
  }

  return (
    <div className="w-full" style={{ padding: '0px 30px' }}>
      <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
        <div>Security</div>
        {user!.hasPassword && (
          <div style={{ marginLeft: 'auto' }}>
            <Button onClick={() => setShowChangeLogin(true)} data-cy="change-password-button">
              Change Password
            </Button>
          </div>
        )}
      </div>

      <div className="flex mb-20px">
        <div className="text-secondary" style={{ flex: 1 }}>
          Email
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          {user!.email}
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="text-secondary" style={{ flex: 1 }}>
          Password
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          {user!.hasPassword ? 'Yes' : 'No (Login via OAuth)'}
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="flex text-secondary items-center" style={{ flex: 1 }}>
          Support Access
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          <Checkbox
            checked={user?.allowSupportAccess}
            onChange={(event) =>
              user?.is2FaEnabled ? setShowSupportAccess({ open: true }) : handleAllowSupportAccess(event.target.checked)
            }
          >
            Allow Morta support to access my account
          </Checkbox>
        </div>
      </div>

      <div className="flex flex-row items-center w-full text-lg font-bold my-30px py-10px border-b-1px border-solid border-grey">
        <div>Two factor authentication</div>
        <div style={{ marginLeft: 'auto' }}>
          {user!.is2FaEnabled && <Button onClick={() => setShowDisable2Fa(true)}>Disable 2FA</Button>}
          {!user!.is2FaEnabled && <Button onClick={handleSetup2Fa}>Enable 2FA</Button>}
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="text-secondary" style={{ flex: 1 }}>
          2FA enabled
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          {user!.is2FaEnabled ? 'Yes' : 'No'}{' '}
        </div>
      </div>
      <div className="flex flex-row items-center w-full text-lg font-bold my-30px py-10px border-b-1px border-solid border-grey">
        <div>Email Notifications</div>
      </div>

      <div className="flex mb-20px">
        <div className="flex text-secondary items-center" style={{ flex: 1 }}>
          Welcome Emails
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          <Checkbox
            checked={user?.optOutWelcomeEmail}
            onChange={(event) => {
              if (user) {
                handleEmailNotifications(
                  event.target.checked,
                  user.optOutSyncEmail,
                  user.optOutAiEmail,
                  user.optOutHubEmail,
                  user.optOutDuplicationEmail
                )
              }
            }}
          >
            Do Not Receive Welcome Emails
          </Checkbox>
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="flex text-secondary items-center" style={{ flex: 1 }}>
          Sync Emails
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          <Checkbox
            checked={user?.optOutSyncEmail}
            onChange={(event) => {
              if (user) {
                handleEmailNotifications(
                  user.optOutWelcomeEmail,
                  event.target.checked,
                  user.optOutAiEmail,
                  user.optOutHubEmail,
                  user.optOutDuplicationEmail
                )
              }
            }}
          >
            Do Not Receive Sync Emails
          </Checkbox>
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="flex text-secondary items-center" style={{ flex: 1 }}>
          AI Emails
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          <Checkbox
            checked={user?.optOutAiEmail}
            onChange={(event) => {
              if (user) {
                handleEmailNotifications(
                  user.optOutWelcomeEmail,
                  user.optOutSyncEmail,
                  event.target.checked,
                  user.optOutHubEmail,
                  user.optOutDuplicationEmail
                )
              }
            }}
          >
            Do Not Receive AI Emails
          </Checkbox>
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="flex text-secondary items-center" style={{ flex: 1 }}>
          Hub Emails
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          <Checkbox
            checked={user?.optOutHubEmail}
            onChange={(event) => {
              if (user) {
                handleEmailNotifications(
                  user.optOutWelcomeEmail,
                  user.optOutSyncEmail,
                  user.optOutAiEmail,
                  event.target.checked,
                  user.optOutDuplicationEmail
                )
              }
            }}
          >
            Do Not Receive Hub Emails
          </Checkbox>
        </div>
      </div>

      <div className="flex mb-20px">
        <div className="flex text-secondary items-center" style={{ flex: 1 }}>
          Duplication Emails
        </div>
        <div className="text-primary" style={{ flex: 2 }}>
          <Checkbox
            checked={user?.optOutDuplicationEmail}
            onChange={(event) => {
              if (user) {
                handleEmailNotifications(
                  user.optOutWelcomeEmail,
                  user.optOutSyncEmail,
                  user.optOutAiEmail,
                  user.optOutHubEmail,
                  event.target.checked
                )
              }
            }}
          >
            Do Not Receive Duplication Emails
          </Checkbox>
        </div>
      </div>

      {show2FaSetup && (
        <Modal id={'show-2fa-modal'} open={show2FaSetup} setOpen={setShow2FaSetup} title="Setup 2FA - Step 1">
          <div className="mb-8">
            <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">QR Code</div>
            <p style={{ marginBottom: '20px' }}>Please scan this QR code into your authenticator app</p>
            <QRCode value={setupData!.url} />
          </div>
          <div className="mb-8">
            <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">2FA Code</div>

            <TwoFactorSetupForm onSubmit={handleComplete2FaSetup} />
          </div>
        </Modal>
      )}

      {showRecoveryCodes && (
        <Modal
          id={'show-recovery-codes'}
          open={showRecoveryCodes}
          setOpen={setShowRecoveryCodes}
          title="Setup 2FA - Step 2"
        >
          <div className="mb-8">
            <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Recovery Codes</div>
            <p>Two factor authentication has been setup successfully for your account.</p>
            <br />
            <p>Keep these recovery codes somewhere safe but accessible.</p>
            <br />

            <div className="border-1px border-solid border-grey p-4">
              <li>Each recovery code can only be used once </li>
              <li>These codes were generated on {new Date().toDateString()}</li>
            </div>

            <div
              className="font-semibold border-1px border-solid border-grey p-4 rounded"
              style={{ fontFamily: 'monospace' }}
            >
              {recoveryCodes?.map((code, index) => (
                <div key={code}>
                  <div className="select-none text-secondary">{(index + 1).toString().padStart(2, '0')}.</div> {code}
                </div>
              ))}
            </div>

            <div className="flex" style={{ margin: '1rem 0 4rem 0' }}>
              <div>
                <div className="flex items-center relative cursor-pointer" onClick={handleCopy}>
                  <Copy />
                  <span className="relative" style={{ marginLeft: '0.25rem' }}>
                    Copy codes to clipboard
                  </span>
                  {copying ? <span className="copying">Copied!</span> : null}
                </div>
              </div>
              <div className="flex items-center ml-4 cursor-pointer" onClick={handleDownloadRecoveryCodes}>
                Download recovery codes
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button style={{ marginTop: '20px' }} onClick={() => setShowRecoveryCodes(false)}>
              I have copied these recovery codes
            </Button>
          </div>
        </Modal>
      )}

      {showDisable2Fa && (
        <Modal id={'show-disable-2fa-modal'} open={showDisable2Fa} setOpen={setShowDisable2Fa} title="Disable 2FA">
          <TwoFactorDisableForm onSubmit={handleTwoFactorDisable} />
        </Modal>
      )}

      {showChangeLogin && (
        <Modal
          id={'show-change-login-modal'}
          open={showChangeLogin}
          setOpen={setShowChangeLogin}
          title="Change Password"
        >
          <UserAccountForm setShowChangeLogin={setShowChangeLogin} />
        </Modal>
      )}

      {showSupportAccess.open && (
        <Modal
          id={'show-change-login-modal'}
          open={showSupportAccess.open}
          setOpen={(open) => setShowSupportAccess({ ...showSupportAccess, open })}
          title={`${user?.allowSupportAccess ? 'Disable' : 'Enable'} Support Access`}
        >
          <SupportAccessForm
            onSupportAccessChange={handleAllowSupportAccess}
            allowSupportAccess={user && user.allowSupportAccess ? true : false}
          />
        </Modal>
      )}
    </div>
  )
}

export default Security
